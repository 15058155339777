import React from 'react';
import styles from './multipleNetworks.module.css';

import BTC from '../../assets/images/main/network-icon-btc.svg';
import KLAY from '../../assets/images/main/network-icon-klay.svg';
import BNB from '../../assets/images/main/network-icon-bsc.svg';
import SOL from '../../assets/images/main/network-icon-sol.svg';
import ETH from '../../assets/images/main/network-icon-eth.svg';
import {useTranslation} from "react-i18next";


const MultipleNetworks = () => {
  const {t} = useTranslation()
  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className="common_Btn">FLEXIBILITY</div>
        <div className={styles.title}>MULTIPLE NETWORKS</div>
        <p className={styles.description}>{t("multipleNetworks-description")}</p>
        <div className={styles.logo}>
          <img src={BTC} alt="Icon"/>
          <img src={KLAY} alt="Icon"/>
          <img src={BNB} alt="Icon"/>
          <img src={SOL} alt="Icon"/>
          <img src={ETH} alt="Icon"/>
        </div>
      </div>
    </section>
  );
};

export default MultipleNetworks;
