import React from 'react';
import GetInTouch from "../components/getInTouch/getInTouch";
import Process from "../components/process/process";
import Faq from "../components/faq/faq";

const ContactUs = () => {
  return (
    <main>
      <GetInTouch />
      <Process />
      <Faq />
    </main>
  );
};

export default ContactUs;
