import React, {useState, useEffect} from 'react';
import styles from './financialInvestment.module.css';
import {Trans, useTranslation} from "react-i18next";
import i18n from "i18next";

const FinancialInvestment = () => {
  const {t} = useTranslation();
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])

  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className="common_Btn_bk">{t("financialInvestment-button")}</div>
        <div className={`${styles.top} ${language === 'ko-KR' && styles.korean}`}>
          <div className={styles.title}><Trans i18nKey="financialInvestment-title" /></div>
          <div className={styles.description}>{t("financialInvestment-description")}</div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.stage}>
            <p className={styles.stage_tit}>Early Stage</p>
            <p className={styles.contents}>{t("financialInvestment-stage1")}</p>
          </div>
          <div className={styles.stage}>
            <p className={styles.stage_tit}>Mid Stage</p>
            <p className={styles.contents}>{t("financialInvestment-stage2")}</p>
          </div>
          <div className={styles.stage}>
            <p className={styles.stage_tit}>Late Stage</p>
            <p className={styles.contents}>{t("financialInvestment-stage3")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinancialInvestment;
