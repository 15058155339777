import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './app.css'
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import './lang/i18n';
import {HelmetProvider} from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <ScrollToTop />
      <App />
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();
