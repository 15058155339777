import React from 'react';
import WeAreHiring from "../components/weAreHiring/weAreHiring";
import CareerOpportunities from "../components/careerOpportunities/careerOpportunities";
import SendResume from "../components/sendResume/sendResume";



const Careers = () => {
  return (
    <>
      <WeAreHiring />
      <CareerOpportunities />
      <SendResume />
    </>
  );
};

export default Careers;
