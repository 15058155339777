import React, {useEffect, useRef} from 'react';
import styles from './getInTouch.module.css';
import Form from "../form/form";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const GetInTouch = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const formRef = useRef(null);

  useEffect(() => {
    if (location.state === 'contactus' && window.innerWidth < 1200) {
      // const scrollHeight = formRef.current.getBoundingClientRect().top - 56;
      // window.scrollBy(0, scrollHeight);
      formRef.current.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
          <div className={styles.getInTouch_left}>
            <div className={styles.title}>{t("getInTouch-title")}</div>
            <ul className={styles.inquiry}>
              <li>
                <p>{t("getInTouch-inquiry1")}</p>
                <div className={styles.email}><a href="mailto:consulting@monosig.com>">consulting@monosig.com</a></div>
              </li>
              <li>
                <p>{t("getInTouch-inquiry2")}</p>
                <div className={styles.email}><a href="mailto:development@monosig.com">development@monosig.com</a></div>
              </li>
              <li>
                <p>{t("getInTouch-inquiry3")}</p>
                <div className={styles.email}><a href="mailto:investment@monosig.com">investment@monosig.com</a></div>
              </li>
              <li>
                <p>{t("getInTouch-inquiry4")}</p>
                <div className={styles.email}><a href="mailto:partnerships@monosig.com">partnerships@monosig.com</a></div>
              </li>
              <li>
                <p>{t("getInTouch-inquiry5")}</p>
                <div className={styles.email}><a href="mailto:official@monosig.com">official@monosig.com</a></div>
              </li>
            </ul>
          </div>

          <div className={styles.getInTouch_right} ref={formRef}>
            <div className={styles.getInTouch_form_wrap}>
              <Form />
            </div>
          </div>
      </div>
    </section>
  );
};

export default GetInTouch;
