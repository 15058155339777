import React from 'react';
import styles from './mainVisual.module.css';
import visualImg from '../../assets/images/main/visual_logo.png';
import {Trans, useTranslation} from "react-i18next";

const MainVisual = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.section1}>
      <div className={styles.section_wrap}>
        <div className={styles.section_contents_wrap}>
          <div className={styles.section_tit}><Trans i18nKey="mainVisual-title"/></div>
          <div className={styles.section_con}>{t('mainVisual-contents')}</div>
        </div>
        <img src={visualImg} alt="Image decoration" className={styles.img1}/>
      </div>
    </section>
  );
};

export default MainVisual;
