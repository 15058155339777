import React, {useRef, useEffect, useState} from 'react';
import styles from './servicesOffered.module.css';

import Market from "../../assets/images/main/service-icon-market.svg";
import Cex from "../../assets/images/main/service-icon-cex.svg";
import Mainnet from "../../assets/images/main/service-icon-mainnet.svg";
import P2e from "../../assets/images/main/service-icon-p2e.svg";
import Metaverse from "../../assets/images/main/service-icon-metaverse.svg";
import Nft from "../../assets/images/main/service-icon-nft.svg";
import Wallet from "../../assets/images/main/service-icon-wallet.svg";
import Consulting from "../../assets/images/main/service-icon-consulting.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {scrollToDevelopment} from "../../utils/utils";
import {Trans, useTranslation} from "react-i18next";
import Marketplace from '../../assets/images/main/marketplace.png';
import CEX from '../../assets/images/main/cex.png';
import DEX from '../../assets/images/main/dex.png';
import MainNet from '../../assets/images/main/mainnet.png';
import popup_NFTMarketplace from '../../assets/images/main/popup_NFTMarketplace.png';
import popup_DEX from '../../assets/images/main/popup_DEX.png';
import popup_CEX from '../../assets/images/main/popup_CEX.png';
import popup_MainNet from '../../assets/images/main/popup_Mainnet.png';
import ko_popup_NFTMarketplace from '../../assets/images/main/ko_popup_NFTMarketplace.png';
import ko_popup_DEX from '../../assets/images/main/ko_popup_DEX.png';
import ko_popup_CEX from '../../assets/images/main/ko_popup_CEX.png';
import ko_popup_MainNet from '../../assets/images/main/ko_popup_Mainnet.png';

const ServicesOffered = ({ setPopupData }) => {
  const { t } = useTranslation();
  const [serviceHover, setServiceHover] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const language = window.localStorage.getItem('language');
  const services = [
    {
      id: 1,
      title: t('modal-tit1'),
      description1: t('modal-des1-1'),
      description2: t('modal-des1-2'),
      image: language === 'en-US' ? popup_NFTMarketplace : ko_popup_NFTMarketplace,
      thumbnail: Marketplace,
    },
    {
      id: 2,
      title: t('modal-tit2'),
      description1: t('modal-des2-1'),
      description2: t('modal-des2-2'),
      image: language === 'en-US' ? popup_DEX : ko_popup_DEX,
      thumbnail: DEX,
    },
    {
      id: 3,
      title: t('modal-tit3'),
      description1: t('modal-des3-1'),
      description2: t('modal-des3-2'),
      image: language === 'en-US' ? popup_CEX : ko_popup_CEX,
      thumbnail: CEX,
    },
    {
      id: 4,
      title: t('modal-tit4'),
      description1: t('modal-des4-1'),
      description2: t('modal-des4-2'),
      image: language === 'en-US' ? popup_MainNet : ko_popup_MainNet,
      thumbnail: MainNet,
    }
  ]

  useEffect(() => {
    if (location.state) {
      scrollToDevelopment();
    }
  }, [])

  return (
    <section className={styles.section} id="development">
      <div className={styles.section_wrap}>
        <div className="common_Btn_bk">DEVELOPMENT</div>
        <div className={styles.main_tit}>SERVICES OFFERED</div>
        <div className={styles.project}>
          {services.map(list => (
            <div
              key={list.id}
              className={styles.list_wrap}
            >
              <img
                key={list.id}
                src={list.thumbnail}
                alt={list.title}
                onMouseEnter={() => setServiceHover(list.id)}
              />
              {list.id === serviceHover ? (
                <div
                  className={styles.list}
                  onClick={() => setPopupData(list)}
                  onMouseLeave={() => setServiceHover(0)}
                >
                  <div className={styles.list_bg}>
                    <div>
                      <div>Blockchain Development</div>
                      <div className={styles.list_bg_tit}>{list.title}</div>
                    </div>
                    <div className={styles.list_bg_bot}>Learn More</div>
                  </div>
                </div>
              ) : null}
              </div>
          ))
          }
        </div>

        <div className={styles.description}>
          <div className={styles.description_list}>
            <img src={Market} alt="Icon"/>
            <div className={styles.title}>NFT Marketplace</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-nft-market' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Cex} alt="Icon"/>
            <div className={styles.title}>CEX & DEX</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-cex-dex' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Mainnet} alt="Icon"/>
            <div className={styles.title}>Mainnet</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-mainnet' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={P2e} alt="Icon"/>
            <div className={styles.title}>P2E</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-p2e' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Metaverse} alt="Icon"/>
            <div className={styles.title}>Metaverse</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-metaverse' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Nft} alt="Icon"/>
            <div className={styles.title}>NFT</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-nft' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Wallet} alt="Icon"/>
            <div className={styles.title}>Crypto Wallet</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-crypto-wallet' /></div>
          </div>
          <div className={styles.description_list}>
            <img src={Consulting} alt="Icon"/>
            <div className={styles.title}>Consulting</div>
            <div className={styles.contents}><Trans i18nKey='servicesOffered-consulting' /></div>
          </div>
        </div>

        <div className={styles.button}><span onClick={() => navigate('/contactus', { state: 'contactus'})}>{t('servicesOffered-button')}</span></div>
      </div>
    </section>
  );
};

export default ServicesOffered;
