import React from 'react';
import PageNotFound from "../components/pageNotFound/pageNotFound";

const ErrorPage = () => {
  return (
    <main>
      <PageNotFound />
    </main>
  );
};

export default ErrorPage;
