import React from 'react';
import styles from './strategicInvestment.module.css';
import {Trans, useTranslation} from "react-i18next";
import Plan from '../../assets/images/investment/plan.svg';
import Design from '../../assets/images/investment/design.svg';
import Validate from '../../assets/images/investment/validate.svg';
import Scale from '../../assets/images/investment/scale.svg';

const StrategicInvestment = () => {
  const {t} = useTranslation();

  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className="common_Btn">{t("strategicInvestment-button")}</div>
          <div className={styles.top}>
          <div className={styles.title}>{t("strategicInvestment-title")}</div>
          <div className={styles.description}>{t("strategicInvestment-description")}<Trans i18nKey="strategicInvestment-description-sub" /></div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.stage}>
            <div className={styles.icon}><img src={Plan} alt="Plan icon"/></div>
            <p className={styles.stage_tit}>Plan</p>
            <p className={styles.contents}>{t("strategicInvestment-stage1")}</p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon}><img src={Design} alt="Design icon"/></div>
            <p className={styles.stage_tit}>Design</p>
            <p className={styles.contents}>{t("strategicInvestment-stage2")}</p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon}><img src={Validate} alt="Validate icon"/></div>
            <p className={styles.stage_tit}>Validate</p>
            <p className={styles.contents}>{t("strategicInvestment-stage3")}</p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon}><img src={Scale} alt="Scale icon"/></div>
            <p className={styles.stage_tit}>Scale</p>
            <p className={styles.contents}>{t("strategicInvestment-stage4")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StrategicInvestment;
