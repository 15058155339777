import React from 'react';
import styles from './globalImpact.module.css';

import Section2BG from "../../assets/images/main/bg-abstract-small.png";
import SlideBanner from "../slideBanner/slideBanner";

const GlobalImpact = () => {
  return (
    <section
      className={styles.section2}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${Section2BG})`,
        backgroundPosition: 'left top'
      }}
    >
      <div className={styles.section2_wrap}>
        <div className="common_Btn">DIVERSITY</div>
        <div className={styles.data_tit}>GLOBAL IMPACT</div>
        <div className={styles.data_wrap}>
          <div className={styles.data}>
            <div>70+</div>
            <p>projects engaged</p>
          </div>
          <div className={styles.data}>
            <div>4</div>
            <p>different countries</p>
          </div>
          <div className={styles.data}>
            <div>100+</div>
            <p>team members</p>
          </div>
        </div>
      </div>
      <SlideBanner />
    </section>
  );
};

export default GlobalImpact;
