import React from 'react';
import styles from "./footer.module.css";
import {Link, useNavigate} from "react-router-dom";
import Logo from "../../assets/images/common/monosig-logo-white.png";
import {scrollToDevelopment} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const handleClick = () => {
    navigate('/', { state: true})
    scrollToDevelopment()
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_wrap}>
        <div className={styles.footer_top}>
          <div className={styles.footer_left}>
            <div className={styles.footer_left_top}>
              <div className={styles.logo}>
                <Link to="/"><img src={Logo} alt="Monosig logo" onClick={scrollToTop}/></Link>
                </div>
              <p className={styles.description}>Disrupting the future with exciting ideas</p>
            </div>
            <div className={styles.email}><a href="mailto:official.monosig.com">official@monosig.com</a></div>
          </div>

          <nav className={styles.nav}>
            <ul className={styles.footer_nav}>
              <li className={styles.nav_item}>
                <p>MENU</p>
              </li>
              <li className={styles.nav_item}>
                <Link to="/aboutus">{t("nav-about-us")}</Link>
              </li>
              <li className={styles.nav_item}>
                <Link to="/investment">{t("nav-investment")}</Link>
              </li>
              <li className={styles.nav_item} onClick={handleClick}>
                {t("nav-development")}
              </li>
              <li className={styles.nav_item}>
                <Link to="/careers">{t("nav-careers")}</Link>
              </li>
              <li className={styles.nav_item}>
                <Link to="/contactus">{t("nav-contact-us")}</Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className={styles.footer_bot}>
          <span>Copyright 2021 Monosig All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
