import React from 'react';
import styles from './contactus.module.css';
import Form from "../form/form";
import {useTranslation} from "react-i18next";

const Contactus = () => {
  const {t} = useTranslation()
  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className={styles.section_tit}>{t("contactUs-title")}</div>
        <div className={styles.form_wrap}>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Contactus;
