import React from 'react';
import styles from './weAreHiring.module.css'
import LogoBlack from '../../assets/images/common/monosig-logo-black.png'
import Map from "../map/map";
import {useTranslation} from "react-i18next";

const WeAreHiring = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <h2 className={styles.title}>{t("weAreHiring-title")}</h2>
          <p className={styles.content}>{t("weAreHiring-content")}</p>
        </div>
        <div className={styles.map}>
          <Map />
        </div>
      </div>
    </section>
  );
};

export default WeAreHiring;
