import {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/header/header";
import Main from "./pages/main";
import ContactUs from "./pages/contactUs";
import Investment from "./pages/investment";
import AboutUs from "./pages/aboutUs";
import Careers from "./pages/careers";
import Footer from "./components/footer/footer";
import ErrorPage from "./pages/errorPage";
import {changeFontByLanguage, nowLanguage} from "./utils/utils";
import {Helmet} from "react-helmet-async";


function App() {
  const [popupData, setPopupData] = useState({});
  const [language, setLanguage] = useState('en-US');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      setPopupData({})
    }
  }, [location])

  useEffect(() => {
    const body = document.querySelector('body');
    if (popupData.title) {
      body.classList.add('show_modal')
      return;
    }
    body.classList.remove('show_modal')
  }, [popupData])

  useEffect(() => {
    changeFontByLanguage();
    setLanguage(nowLanguage);
  }, [])

  return (
    <>
      <Helmet>
        {language === 'en-US' ? (
          <>
            <meta content="Monosig is a development and investment company looking for technologies and companies with the potential to change the future. With more than 70 projects across South Korea, Indonesia, United States, and Hong Kong, our portfolio includes projects in the industries of mobile and web applications, gaming, fintech, social media, and metaverse." name="description" />
            <meta content="Monosig" property="og:title" />
            <meta content="Monosig is a development and investment company looking for technologies and companies with the potential to change the future. With more than 70 projects across South Korea, Indonesia, United States, and Hong Kong, our portfolio includes projects in the industries of mobile and web applications, gaming, fintech, social media, and metaverse." property="og:description" />
            <meta content="Monosig" property="twitter:title" />
            <meta content="Monosig is a development and investment company looking for technologies and companies with the potential to change the future. With more than 70 projects across South Korea, Indonesia, United States, and Hong Kong, our portfolio includes projects in the industries of mobile and web applications, gaming, fintech, social media, and metaverse." property="twitter:description" />
            <meta property="og:type" content="website" />
            <meta content="summary_large_image" name="twitter:card" />
            <meta content="width=device-width, initial-scale=1" name="viewport" />
            <meta name="robots" content="index, follow" />
            <meta property="og:type" content="website" />
            <meta property="site_name" content="Monosig" />
          </>
        ) : (
          <>
            <meta content="모노시그는 미래를 바꿀 잠재력이 있는 기술, 또는 기업을 발굴하여 이들이 성장하도록 돕는 투자 회사입니다. 한국, 인도네시아, 미국 및 홍콩에 걸쳐 70개 이상의 프로젝트를 진행하고 있는 우리의 포트폴리오에는 모바일 및 웹 어플리케이션, 게임, 핀테크, 소셜 미디어 및 메타버스 분야의 프로젝트들이 포함되어 있습니다." name="description" />
            <meta content="모노시그 - 당신과 함께하는 가치의 중요성을 아는 기업" property="og:title" />
            <meta content="모노시그는 미래를 바꿀 잠재력이 있는 기술, 또는 기업을 발굴하여 이들이 성장하도록 돕는 투자 회사입니다. 한국, 인도네시아, 미국 및 홍콩에 걸쳐 70개 이상의 프로젝트를 진행하고 있는 우리의 포트폴리오에는 모바일 및 웹 어플리케이션, 게임, 핀테크, 소셜 미디어 및 메타버스 분야의 프로젝트들이 포함되어 있습니다." property="og:description" />
            <meta content="모노시그 - 당신과 함께하는 가치의 중요성을 아는 기업" property="twitter:title" />
            <meta content="모노시그는 미래를 바꿀 잠재력이 있는 기술, 또는 기업을 발굴하여 이들이 성장하도록 돕는 투자 회사입니다. 한국, 인도네시아, 미국 및 홍콩에 걸쳐 70개 이상의 프로젝트를 진행하고 있는 우리의 포트폴리오에는 모바일 및 웹 어플리케이션, 게임, 핀테크, 소셜 미디어 및 메타버스 분야의 프로젝트들이 포함되어 있습니다." property="twitter:description" />
            <meta property="og:image:alt" content="모노시그 - 당신과 함께하는 가치의 중요성을 아는 기업" />
            <meta name="twitter:title" content="모노시그 - 당신과 함께하는 가치의 중요성을 아는 기업" />
            <meta name="twitter:description" content="모노시그는 미래를 바꿀 잠재력이 있는 기술, 또는 기업을 발굴하여 이들이 성장하도록 돕는 투자 회사입니다. 한국, 인도네시아, 미국 및 홍콩에 걸쳐 70개 이상의 프로젝트를 진행하고 있는 우리의 포트폴리오에는 모바일 및 웹 어플리케이션, 게임, 핀테크, 소셜 미디어 및 메타버스 분야의 프로젝트들이 포함되어 있습니다." />
            <title>모노시그 - 당신과 함께하는 가치의 중요성을 아는 기업</title>
          </>
        )}
      </Helmet>

      <Header setLanguage={setLanguage} />
      <Routes>
        <Route path="/" element={<Main popupData={popupData} setPopupData={setPopupData} />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path={"*"} element={<ErrorPage />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
