import React, {useEffect} from 'react';
import InvestmentVisual from "../components/investmentVisual/investmentVisual";
import Mission from "../components/mission/mission";
import FinancialInvestment from "../components/financialInvestment/financialInvestment";
import StrategicInvestment from "../components/strategicInvestment/strategicInvestment";
import Faq from "../components/faq/faq";
import Contactus from "../components/contactus/contactus";
import i18n from "i18next";

const Investment = () => {
  return (
    <>
      <InvestmentVisual />
      <Mission showBanner={true} />
      <FinancialInvestment />
      <StrategicInvestment />
      <Faq />
      <Contactus />
    </>
  );
};

export default Investment;
