import React from 'react';
import styles from './investmentVisual.module.css';
import {Trans, useTranslation} from "react-i18next";
import {nowLanguage} from "../../utils/utils";

const InvestmentVisual = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div
          className={styles.title}
          style={nowLanguage() === 'en-US' ? {fontWeight: '400'} : {fontWeight: '700'}}
        >{t("investmentVisual-title")} <p className={styles.bold}><Trans i18nKey="investmentVisual-title-bold" /></p></div>
        <p className={styles.description}>{t("investmentVisual-description1")}<br/>{t("investmentVisual-description2")}</p>
      </div>
    </section>
  );
};

export default InvestmentVisual;
