import React, {useEffect, useState} from 'react';
import MainVisual from "../components/mainVisual/mainVisual";
import GlobalImpact from "../components/globalImpact/globalImpact";
import ServicesOffered from "../components/servicesOffered/servicesOffered";
import MultipleNetworks from "../components/multipleNetworks/multipleNetworks";
import Consulting from "../components/consulting/consulting";
import Process from "../components/process/process";
import Faq from "../components/faq/faq";
import ContactUs from "../components/contactus/contactus";
import Modal from "../components/modal/modal";

const Main = ({ popupData, setPopupData }) => {

  return (
    <>
      <MainVisual />
      <GlobalImpact />
      <ServicesOffered setPopupData={setPopupData} />
      <MultipleNetworks />
      <Consulting />
      <Process />
      <Faq />
      <ContactUs />
      {popupData.title ? <Modal popupData={popupData} setPopupData={setPopupData} /> : null}
    </>
  );
};

export default Main;
