import React, {useEffect, useRef, useState} from 'react';
import styles from './aboutUsValuse.module.css';
import {Player} from "@lottiefiles/react-lottie-player";

const AboutUsValues = ({ data }) => {
  const lottieRef = useRef(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const play = () => {
    lottieRef.current.setPlayerDirection(1);
    lottieRef.current.play(true);
  }

  const reverse = () => {
    lottieRef.current.setPlayerDirection(-1)
    lottieRef.current.play(true);
  }

  return (
    <section id={`${data.moreId ? data.moreId : ''}`} className={styles.section}>
      <div className={styles.section_wrap}>
        <div className={styles.section_left}>
          <div className="common_Btn_bk">VALUES</div>
          <div className={styles.tit}>{data.title}</div>
          <div className={styles.des}>{data.description}</div>
        </div>

        <div
          className={styles.section_right}
          onMouseEnter={() => play()}
          onMouseLeave={() => reverse()}
        >
          <Player
            src={data.image}
            style={windowSize > 1200 ? {width: 500, height: 500} : {width: 200, height: 200}}
            autoplay={false}
            loop={false}
            keepLastFrame={true}
            renderer={'svg'}
            ref={lottieRef}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsValues;
