import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';

const lang = () => {
  const local = window.localStorage.getItem('language')
  if (!local) {
    window.localStorage.setItem('language', 'en-US')
    return 'en-US'
  }
  return local
}

const resource =  {
  'en-US': {
    translation: langEn
  },
  'ko-KR': {
    translation: langKo
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: resource,
    lng: lang(),
    fallbackLng: {
      'ko-KR':['ko-KR'],
      default:[lang()]
    },
    debug: false,
    defaultNS: 'translation',
    ns: 'translation',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })

export default i18n;
