import React from 'react';
import styles from './consulting.module.css';
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const Consulting = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className="common_Btn_bk">CONSULTING</div>
        <div className={styles.main_tit}>{t("consulting-title")}</div>
        <div className={styles.sub_tit}>{t("consulting-subtitle1")}<br/><br/>
          <Trans i18nKey="consulting-subtitle2" /></div>
        <div className={styles.sales}><span onClick={() => navigate('/contactus', { state: 'contactus'})}>{t("consulting-button")}</span></div>
      </div>
    </section>
  );
};

export default Consulting;
