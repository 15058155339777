import React, {useEffect} from 'react';
import styles from './modal.module.css';
import CloseBtn from '../../assets/images/common/close.svg';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Modal = ({ popupData, setPopupData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
    <div className={styles.modal_wrap}>
      <div className={styles.modal} onClick={() => setPopupData({})}></div>
      <div className={styles.popup}>
        <div className={styles.close_btn}><img src={CloseBtn} alt="Close popup button" onClick={() => setPopupData({})}/></div>
        <div className={styles.wrap_header}>
          <div className={styles.popup_tit}>{popupData.title}</div>
          <div className={styles.popup_des}>{popupData.description1}</div>
          {popupData.description2 ? <div className={styles.popup_des2}>{popupData.description2}</div> : null}
          <div className={styles.sales_btn}>
            <span onClick={() => navigate('/contactus', { state: 'contactus'})}>{t('modal-btn')}</span>
          </div>
        </div>
        <div className={styles.body_image}>
          <img src={popupData.image} alt="Service contents" />
        </div>
      </div>
    </div>
    </>
  );
};

export default Modal;
