import React from 'react';
import Collaboration from "../components/collaboration/collaboration";
import Mission from "../components/mission/mission";
import AboutUsValues from "../components/aboutUsValues/aboutUsValues";
import CollaborationData from "../assets/json/Values-collab.json";
import CustomerData from "../assets/json/Values-centered.json";
import TailoredData from "../assets/json/Values-tailor.json";
import {useTranslation} from "react-i18next";



const AboutUs = () => {
  const { t } = useTranslation();
  const animationData = [
    {
      id: 0,
      image: CollaborationData,
      title: 'Collaboration',
      description: t("aboutUsValues-content1")
    },
    {
      id: 1,
      image: CustomerData,
      title: 'Customer-Centered',
      description: t("aboutUsValues-content2")
    },
    {
      id: 2,
      image: TailoredData,
      title: 'Tailored Services',
      description: t("aboutUsValues-content3"),
      moreId: 'last-value-section'
    }
  ]

  return (
    <>
      <Collaboration />
      <Mission showBanner={false} />
      {animationData.map(list => (
        <AboutUsValues data={list} key={list.id} />
      ))}
    </>
  );
};

export default AboutUs;
