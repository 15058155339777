import React, {useState} from 'react';
import styles from './process.module.css';

import ProcessImage from '../../assets/images/main/process-desktop.png';
import ProcessImageMobile from '../../assets/images/main/process_mobile.png';
import {Trans, useTranslation} from "react-i18next";

const Process = () => {
  const {t} = useTranslation()
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  })

  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className={styles.tit}>PROCESS</div>

        <div className={styles.process_con_wrap}>
          <img src={windowSize < 1200 ? ProcessImageMobile : ProcessImage} alt="Process" className={styles.process_image}/>
          <ul className={styles.description_wrap}>
            <li className={styles.description}>
              <Trans i18nKey="process-description1" />
            </li>
            <li className={styles.description}>
              <Trans  i18nKey="process-description2"  />
            </li>
            <li className={styles.description}>
              <Trans  i18nKey="process-description3" />
            </li>
            <li className={styles.description}>
              <Trans  i18nKey="process-description4"/>
            </li>
            <li className={styles.description}>
              <Trans  i18nKey="process-description5"/>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Process;
