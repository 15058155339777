export const scrollToDevelopment = () => {
  const dev = document.querySelector('#development');
  const scrollHeight = dev ? dev.getBoundingClientRect().top : 0;
  window.scrollBy(0, scrollHeight);
}

export const nowLanguage = () => {
  const lang = window.localStorage.getItem('language');
  return lang ? lang : 'en-US'
}

export const changeFontByLanguage = () => {
  const dev = document.querySelector('#root');
  const lang = nowLanguage();
  if (lang === 'en-US') {
    dev.classList.remove('ko');
    dev.classList.add('en');
    return;
  }
  dev.classList.remove('en');
  dev.classList.add('ko');
}
