import React from 'react';
import styles from './pageNotFound.module.css'
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  }

  return (
    <section className={styles.page}>
      <h4 className={styles.code}>404</h4>
      <h5 className={styles.title}>{t("pageNotFound-title")}</h5>
      <div className={styles.content}><Trans i18nKey="pageNotFound-content" /></div>
      <button onClick={handleClick} className={styles.button}>{t("pageNotFound-button")}</button>
    </section>
  );
};

export default PageNotFound;
