import React from 'react';
import styles from './careerOpportunities.module.css'
import {useTranslation} from "react-i18next";

const CareerOpportunities = () => {
  const { t } = useTranslation()
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
      <h3 className={styles.title}>{t("careerOpportunities-title1")}<br/>{t("careerOpportunities-title2")}</h3>
      <div className={styles.content}>
        <ul className={styles.list}>
          <li className={`${styles.item} ${styles.item_title}`}>{t("careerOpportunities-list1")}</li>
          <li className={styles.item}>BD Representative</li>
          <li className={styles.item}>Growth Architect</li>
          <li className={styles.item}>Content Marketing</li>
          <li className={styles.item}>Community Manager</li>
          <li className={styles.item}>Consultant</li>
        </ul>
        <ul className={styles.list}>
          <li className={`${styles.item} ${styles.item_title}`}>{t("careerOpportunities-list2")}</li>
          <li className={styles.item}>Account Manager</li>
          <li className={styles.item}>Internal Communications</li>
          <li className={styles.item}>Contracts Manager</li>
        </ul>
        <ul className={styles.list}>
          <li className={`${styles.item} ${styles.item_title}`}>{t("careerOpportunities-list3")}</li>
          <li className={styles.item}>Front-End Engineer</li>
          <li className={styles.item}>Back-End Engineer</li>
          <li className={styles.item}>Android Engineer</li>
          <li className={styles.item}>IOS Engineer</li>
          <li className={styles.item}>Product Manager </li>
        </ul>
      </div>
      </div>
    </section>
  );
};

export default CareerOpportunities;
