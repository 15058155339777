import React from 'react';
import styles from './sendResume.module.css'
import {useTranslation} from "react-i18next";

const SendResume = () => {
  const {t} = useTranslation()
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
      <h2 className={styles.title}>{t("sendResume-title1")}<br/>{t("sendResume-title2")}</h2>
      <div className={styles.content}>
        <p className={styles.paragraph}>
        <span className={styles.text}>{t("sendResume-text1")}</span>
        <span className={styles.text}>{t("sendResume-text2")}</span>
        </p>

        <span className={`${styles.text} ${styles.bold}`}><a href="mailto:official@monosig.com">official@monosig.com</a></span>
      </div>
      </div>
    </section>
  );
};

export default SendResume;
