import React from 'react';
import styles from './faq.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Faq = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const faqLists = [
    {
      id: 0,
      title: t("faq-q0"),
      contents: t("faq-a0")
    },
    {
      id: 1,
      title: t("faq-q1"),
      contents: `${t("faq-a1")}`,
      link: t("faq-a1-1")
    },
    {
      id: 2,
      title: t("faq-q2"),
      contents: t("faq-a2")
    },
    {
      id: 3,
      title: t("faq-q3"),
      contents: t("faq-a3")
    },
    {
      id: 4,
      title: t("faq-q4"),
      contents: t("faq-a4")
    },
  ];

  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div className={styles.section_tit}>FAQ</div>
        <div className={styles.lists}>
          {faqLists.map(list => (
            <div className={styles.list} key={list.id}>
              <div>
                <div className={styles.list_header}>{list.title}</div>
              </div>
              <div>
                {list.link
                ? <div className={styles.list_body}>{list.contents} <span className={styles.link} onClick={() => navigate('/contactus', { state: 'contactus'})}>{list.link}</span> </div>
                : <div className={styles.list_body}>{list.contents}</div>
                }
              </div>
            </div>
            // <Accordion className={styles.list} key={list.id}>
            //   <AccordionSummary
            //     expandIcon={<ExpandMoreIcon />}
            //     aria-controls="panel1a-content"
            //     id="panel1a-header"
            //   >
            //     <Typography className={styles.list_header}>{list.title}</Typography>
            //   </AccordionSummary>
            //   <AccordionDetails>
            //     <Typography className={styles.list_body}>{list.contents}</Typography>
            //     </AccordionDetails>
            // </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
