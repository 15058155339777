import React from 'react';
import styles from './mission.module.css';
import {section2_Banner} from "../globalImpact/globalImpact";
import Section2BG from "../../assets/images/main/bg-abstract-small.png";
import SlideBanner from "../slideBanner/slideBanner";
import {Trans, useTranslation} from "react-i18next";

const Mission = ({showBanner}) => {
  const {t} = useTranslation()

  return (
    <section
      className={styles.section}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${Section2BG})`,
        backgroundPosition: 'left top'
      }}
    >
      {showBanner ? (
        <SlideBanner />
      ) : null}
      <div className={styles.section_wrap}>
        <div className="common_Btn">MISSION</div>
        <div className={styles.contents}>
          <Trans i18nKey="mission-content" />
        </div>
      </div>
    </section>
  );
};

export default Mission;
