import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import styles from './header.module.css'
import Logo from '../../assets/images/common/monosig-logo-white.png'
import Globe from '../../assets/images/header/menu-globe-icon.svg';
import {changeFontByLanguage, scrollToDevelopment} from "../../utils/utils";
import MenuIcon from '../../assets/images/header/menu-icon.png'
import CloseIcon from '../../assets/images/header/close-icon.png'
import i18n from "../../lang/i18n";
import {useTranslation} from "react-i18next";

const Header = ({ setLanguage }) => {
  const [mobileNav, setMobileNav] = useState(false)
  const [languageDropdown, setLanguageDropdown] = useState(false)
  const navigate = useNavigate();

  const {t} = useTranslation();

  const handleClick = () => {
    navigate('/', { state: true})
    scrollToDevelopment()
    setMobileNav(false)
  }

  const handleToggleMenuBar = () => {
    setMobileNav(!mobileNav)
  }

  const clickHandler = (lang) => {
    window.localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    changeFontByLanguage();
    setLanguage(lang);
    setMobileNav(false)
  }

  return (
    <header className={styles.header}>
      <nav className={styles.contents}>
        <div className={styles.logo}>
          <Link to="/">
            <img src={Logo} alt="logo" className={styles.logo_img}/>
          </Link>
        </div>
        <ul className={`${styles.nav} ${mobileNav && styles.active}`}>
            <li onClick={() => setMobileNav(false)} className={styles.nav_item}>
              <Link to="/aboutus">{t("nav-about-us")}</Link>
            </li>
            <li onClick={() => setMobileNav(false)} className={styles.nav_item}>
              <Link to="/investment">{t("nav-investment")}</Link>
            </li>
            <li className={styles.nav_item} onClick={handleClick}>
              {t("nav-development")}
            </li>
            <li onClick={() => setMobileNav(false)} className={styles.nav_item}>
              <Link to="/careers">{t("nav-careers")}</Link>
            </li>
            <li onClick={() => setMobileNav(false)} className={styles.nav_item}>
              <Link to="/contactus">{t("nav-contact-us")}</Link>
            </li>

          {/* mobile version*/}
            <div className={styles.mobile_language}>
              <span className={styles.mobile_language_item} onClick={() => clickHandler('en-US')}>ENGLISH</span>
              <span>|</span>
              <span className={styles.mobile_language_item} onClick={() => clickHandler('ko-KR')}>한국어</span>
            </div>
          </ul>

        <div className={styles.language} onMouseOver={() => setLanguageDropdown(true)} onMouseLeave={() => setLanguageDropdown(false)}>
          <img src={Globe} alt="globe"/>
          {languageDropdown
            && <div className={styles.language_dropdown}>
              <ul>
                <li onClick={() => clickHandler('en-US')}>English</li>
                <li onClick={() => clickHandler('ko-KR')}>한국어</li>
              </ul>
            </div>
          }
        </div>

        {/* mobile version*/}
        <div onClick={handleToggleMenuBar} className={styles.menuIcon}>
          <img src={mobileNav ? CloseIcon : MenuIcon} alt="menu"/>
        </div>
      </nav>
    </header>
  );
};

export default Header;
