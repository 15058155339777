import React, {useEffect, useRef, useState} from 'react';
import styles from "./fom.module.css";
import axios from "axios";
import {useTranslation} from "react-i18next";

const Form = () => {
  const { t } = useTranslation();
  const [showInquiryList, setShowInquiryList] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
    inquiry: '',
    message: '',
  });
  const [reponsePopup, setReponsePopup] = useState({
    title: '',
    content1: '',
    content2: '',
    content3: ''
  });
  const submitBtn = useRef(null);

  const setInquiry = (e) => {
    const data = e.target.innerHTML;
    setFormData({ ...formData, inquiry: data});
    setShowInquiryList(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, company, country, inquiry } = formData;

    if (!firstName || !lastName || !email || !company || !country || !inquiry) {
      setShowError(true);
    } else {
      setShowError(false);
      const checkEmail = validateEmail(email)
      if (!checkEmail) {
        setShowValidationError(true);
        return;
      }
      setShowValidationError(false);
      await postContactUs()
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const postContactUs = async () => {
    try {
      const { data } = await axios.post('https://contactus.monosig.com/api/articles', formData)
      setReponsePopup({
        title: t('form-success'),
        content1: t('form-success-message1'),
        content2: t('form-success-message2')
      })
    } catch(e) {
      console.error(e)
      setReponsePopup({
        title: t('form-fail'),
        content1: t('form-fail-message1'),
        content2: t('form-fail-message2'),
        content3: t('form-fail-message3')
      })
    }
  }

  const resetResponsePopup = () => {
    setReponsePopup({ title: '', conten1t: '', content2: '', content3: '' })
  }

  const handleHover = () => {
    const { firstName, lastName, email, company, country, inquiry } = formData;
    if (firstName && lastName && email && company && country && inquiry) {
      submitBtn.current.classList.add('form_submit_btn_valid');
      return;
    }
    submitBtn.current.classList.remove('form_submit_btn_valid');
  }

  useEffect(() => {
    handleHover();
  }, [formData])

  return (
    <>
      <form className={styles.form_main}>
        <div className={styles.form_line}>
          <div className={styles.form_first_name}>
            <p className={styles.form_subtit}>{t('contactUs-name1')} <span>*</span></p>
            <input
              type="text"
              className={styles.form_input}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value})}
            />
            {showError && !formData.firstName ? <div className={styles.error}>Please enter your first name</div> : null}
          </div>
          <div className={styles.form_last_name}>
            <p className={styles.form_subtit}>{t('contactUs-name2')} <span>*</span></p>
            <input
              type="text"
              className={styles.form_input}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value})}
            />
            {showError && !formData.lastName ? <div className={styles.error}>Please enter your last name</div> : null}
          </div>
        </div>

        <div className={styles.form_line}>
          <div className={styles.form_full_div}>
            <p className={styles.form_subtit}>{t('contactUs-email')} <span>*</span></p>
            <input
              type="email"
              className={styles.form_input}
              onChange={(e) => setFormData({ ...formData, email: e.target.value})}
            />
            {showError && !formData.email ? <div className={styles.error}>Please enter your email</div> : null}
            {showValidationError ? <div className={styles.error}>Invalid email format</div> : null}
          </div>
        </div>

        <div className={styles.form_line}>
          <div className={styles.form_company}>
            <p className={styles.form_subtit}>{t('contactUs-company')} <span>*</span></p>
            <input
              type="text"
              className={styles.form_input}
              onChange={(e) => setFormData({ ...formData, company: e.target.value})}
            />
            {showError && !formData.company ? <div className={styles.error}>Please enter your company name</div> : null}
          </div>
          <div className={styles.form_country}>
            <p className={styles.form_subtit}>{t('contactUs-country')} <span>*</span></p>
            <input
              type="text"
              className={styles.form_input}
              onChange={(e) => setFormData({ ...formData, country: e.target.value})}
            />
            {showError && !formData.country ? <div className={styles.error}>Please enter your country</div> : null}
          </div>
        </div>

        <div className={styles.form_line}>
          <div className={styles.form_full_div}>
            <p className={styles.form_subtit}>{t('contactUs-inquiry')} <span>*</span></p>
            <div className={styles.list}>
              <div
                className={styles.list_header}
                onClick={() => setShowInquiryList(!showInquiryList)
              }>
                <p>{formData.inquiry}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#1B1B1B">
                  {showInquiryList ?
                    <path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"/> :
                    <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/>
                  }
                </svg>
              </div>
              {showInquiryList ? (
                <ul className={styles.list_body}>
                  <li onClick={(e) => setInquiry(e)}>Consulting</li>
                  <li onClick={(e) => setInquiry(e)}>Development</li>
                  <li onClick={(e) => setInquiry(e)}>Investment</li>
                  <li onClick={(e) => setInquiry(e)}>General Inquiries</li>
                  <li onClick={(e) => setInquiry(e)}>Partnerships</li>
                  <li onClick={(e) => setInquiry(e)}>Other</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.form_message}>
          <p className={styles.form_subtit}>{t('contactUs-message')}</p>
          <textarea
            name=""
            className={styles.form_textarea}
            cols="10"
            rows="10"
            onChange={(e) => setFormData({ ...formData, message: e.target.value})}
          ></textarea>
        </div>

        <button
          type="submit"
          className={styles.form_submit_btn}
          onClick={(e) => handleSubmit(e)}
          ref={submitBtn}
        >{t('contactUs-confirm')}</button>
      </form>

      {reponsePopup.title ? (
        <div className={styles.popup_wrap}>
          <div className={styles.popupBg} onClick={resetResponsePopup}></div>
          <div className={styles.popup}>
            <p className={styles.title}>{reponsePopup.title}</p>
            <p className={styles.content1}>{reponsePopup.content1}</p>
            <p className={styles.content}>{reponsePopup.content2}</p>
            {reponsePopup.content3 ? (
              <p className={styles.content}><a href="mailto:official@monosig.com"><b>{reponsePopup.content3}</b></a></p>
            ) : null}
            <button onClick={resetResponsePopup} className={styles.button}>CONFIRM</button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Form;
