import React from 'react';
import styles from './collaboration.module.css';
import {Trans, useTranslation} from "react-i18next";
import LogoBlack from "../../assets/images/common/monosig-logo-black.png";
import {nowLanguage} from "../../utils/utils";

const Collaboration = () => {
  const {t} = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.section_wrap}>
        <div
          className={styles.tit}
          style={nowLanguage() === 'en-US' ? {fontWeight: '400'} : {fontWeight: '700'}}
        ><Trans i18nKey='collaboration-title' /></div>
      </div>
    </section>
  );
};

export default Collaboration;
