import React, {useEffect, useRef, useState} from 'react';
import gsap from 'gsap'
import styles from './map.module.css'
import Map5 from '../../assets/images/careers-img-countries.svg'
import Map4 from '../../assets/images/careers-img-lines.svg'
import Map3 from '../../assets/images/careers-img-location.svg'
import Map1 from '../../assets/images/careers-img-map00.svg'
import Map2 from '../../assets/images/careers-img-map01.svg'

const Map = () => {
  const [mouseOver, setMouseOver] = useState(false)
  const map1 = useRef()
  const map2 = useRef()
  const map3 = useRef()
  const map4 = useRef()
  const map5 = useRef()

  const t1 = useRef()
  const t2 = useRef()
  const t3 = useRef()
  const t4 = useRef()
  const t5 = useRef()

  const containerRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    t1.current = gsap.timeline()
    t2.current = gsap.timeline()
    t3.current = gsap.timeline()
    t4.current = gsap.timeline()
    t5.current = gsap.timeline()

      t1.current.to(map1.current, { duration : 1, opacity: 0, repeat: 0 })
      t1.current.to(map1.current, { duration : 1, x : -600, y : -200, scaleX : 2.5, scaleY : 2.5, repeat: 0 })
      t1.current.to(map1.current, { duration : 2.5, opacity: 0, repeat: 0 })

      t2.current.to(map2.current, { duration : 1, opacity: 1, repeat: 0 })
      t2.current.to(map2.current, { duration : 1, x : -600, y : -200, scaleX : 2.5, scaleY : 2.5, repeat: 0 })
      t2.current.to(map2.current, { duration : 2.5, opacity: 1, repeat: 0 })

      t3.current.to(map3.current, { duration : 1, opacity: 0, repeat: 0 })
      t3.current.to(map3.current, { duration : 1, x : -600, y : -200, scaleX : 2.5, scaleY : 2.5, repeat: 0 })
      t3.current.to(map3.current, { duration : 0.3, opacity: 1, repeat: 0 })
      t3.current.to(map3.current, { duration : 0.3, opacity: 0, repeat: 0 })
      t3.current.to(map3.current, { duration : 0.3, opacity: 1, repeat: 0 })
      t3.current.to(map3.current, { duration : 0.3, opacity: 0, repeat: 0 })
      t3.current.to(map3.current, { duration : 0.3, opacity: 1, repeat: 0 })
      t3.current.to(map3.current, { duration : 1, opacity: 1, repeat: 0 })

      t4.current.to(map4.current, { duration : 1, opacity: 0, repeat: 0 })
      t4.current.to(map4.current, { duration : 1, x : -600, y : -200, scaleX : 2.5, scaleY : 2.5, repeat: 0 })
      t4.current.to(map4.current, { duration : 1.5, opacity: 0, repeat: 0 })
      t4.current.to(map4.current, { duration : 0.5, opacity: 1, repeat: 0 })
      t4.current.to(map4.current, { duration : 0.5, opacity: 1, repeat: 0 })

      t5.current.to(map5.current, { duration : 1, opacity: 0, repeat: 0 })
      t5.current.to(map5.current, { duration : 1, x : -600, y : -200, scaleX : 2.5, scaleY : 2.5, repeat: 0 })
      t5.current.to(map5.current, { duration : 2, opacity: 0, repeat: 0 })
      t5.current.to(map5.current, { duration : 0.5, opacity : 1, repeat: 0 })


    t1.current.pause();
    t2.current.pause();
    t3.current.pause();
    t4.current.pause();
    t5.current.pause();
  }, [])

  useEffect(() => {
    if (mouseOver) {
      onMouseOver()
      return
    }
    onMouseOut()
  }, [mouseOver])

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove)

    return () => window.removeEventListener("mousemove", (e) => onMouseMove(e))
  }, [])

  const onMouseMove = (e) => {
    if (e.target.id !== "ref" || !containerRef.current) {
      return;
    }
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const clientRect = containerRef.current.getBoundingClientRect();
      const widthMargin = (window.innerWidth - clientRect.width) / 2;

      gsap.to(cursorRef.current, {
        left: mouseX - widthMargin + 20,
        top: mouseY - clientRect.top - 20
      })

      gsap.set(cursorRef.current, {
        left: mouseX - widthMargin + 20,
        top: mouseY - clientRect.top - 20
      })
  }

  const onMouseOver = () => {
    t1.current.play()
    t2.current.play()
    t3.current.play()
    t4.current.play()
    t5.current.play()
  }

  const onMouseOut = () => {
    t5.current.reverse();
    t4.current.reverse();
    t3.current.reverse();
    t2.current.reverse();
    t1.current.reverse();
  }

  // useEffect(() => {
  //  window.addEventListener("click", (e) => {console.log(e.target.id)})
  // })

  return (
    <div id="ref" ref={containerRef} className={styles.map_wrapper} onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      {mouseOver && <span ref={cursorRef} className={styles.mouse}>Our offices</span>}
      <div  className={`${styles.map_img} ${styles.first}`}>
        <img ref={map1} src={Map1} alt=""/>
      </div>
      <div  className={styles.map_img}>
        <img ref={map2} src={Map2} alt=""/>
      </div>
      <div   className={styles.map_img}>
        <img ref={map3} src={Map3} alt=""/>
      </div>
      <div   className={styles.map_img}>
        <img ref={map4} src={Map4} alt=""/>
      </div>
      <div  className={styles.map_img}>
        <img id="ref" ref={map5} src={Map5} alt=""/>
      </div>
    </div>
  );
};

export default Map;
